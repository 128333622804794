@keyframes countdown {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 100;
    }
  }
  
  .timerCircle {
    stroke-dasharray: 100;
    animation: countdown 15s linear forwards;
  }
  