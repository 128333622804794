.scrollbar_custom::-webkit-scrollbar {
    width: 8px; /* Reduced scrollbar width */
    height: 7px;
    cursor: pointer;
  }
  
  .scrollbar_custom::-webkit-scrollbar-track {
    background: transparent; /* Track background color */
  }
  
  .scrollbar_custom::-webkit-scrollbar-thumb {
    background: #18162a;
    /*Thumbcolor*/border-radius:20px;
     /* Adjusted border radius for a thinner thumb *  :;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    -ms-border-radius:20px;
    -o-border-radius:20px;
}
  
  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    /* background: #555; Thumb color on hover */
  }
  
  /* For Firefox */
  .scrollbar_custom {
    scrollbar-width: thin; /* 'auto' or 'thin' */
    scrollbar-color: rgb(71, 71, 71) transparent; /* Thumb and track color */
  }
  