/* .custom_scrollbar{
    scrollbar-width: thin;
    scrollbar-color: #5357a3 transparent;
} */
.custom_scrollbar::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  .custom_scrollbar::-webkit-scrollbar-track {
    background: transparent; /* color of the track */
  }

  .custom_scrollbar::-webkit-scrollbar-thumb {
    background-color: #5357a3;
    /*colorofthethumb*/border-radius: 30px;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

  .custom_scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scroll thumb when hovered */
  }


