.shinyText {
    background: linear-gradient(45deg, #d0c7e0, rgb(82, 76, 82), #544f50);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
@keyframes shine {
    to {
      background-position: 200% center;
    }
  }