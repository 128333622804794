.logo_header {
  font-family: 'poopins';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #1E2246;

  text-shadow: 0px 4px 8.2px rgba(0, 0, 0, 0.25);
}

.logo_description {}

.descriptionBox {
  height:208px;
  padding: 20px 20px 29px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 16px 16px 16px 16px;
  -webkit-border-radius: 16px 16px 16px 16px;
  -moz-border-radius: 16px 16px 16px 16px;
  -ms-border-radius: 16px 16px 16px 16px;
  -o-border-radius: 16px 16px 16px 16px;
  box-shadow: 0px 24px 44px 0px #B4BFE166;
  background: #FFFFFF;


}

.descriptiondiv1{
  font-family: "Inter";
font-size: 24px;
font-weight: 700;
line-height: 29.05px;
text-align: left;
color: #0D2167;


}