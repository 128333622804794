.bg-culinary {
    background-color: #ebebed;
    overflow-y: scroll; /* Ensuring only vertical scroll */
    font-weight: bold;
 
}

/* Add the custom scrollbar styles as mentioned in the previous answer. */
/* Custom scrollbar styling */

/* For WebKit browsers */
.bg-culinary::-webkit-scrollbar {
    width: 12px; /* Reduced scrollbar width */
    height: 0px;

}

.bg-culinary::-webkit-scrollbar-track {
  background: transparent; /* color of the track */
}

.bg-culinary::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255); /* color of the thumb */
  border-radius: 5px; /* rounded corners for the thumb */
  border: 2px solid #ebebed; /* create a border around the thumb */
}

/* For Firefox */
.bg-culinary {
  scrollbar-width: thin;
  scrollbar-color: #5357a3 transparent;
}
