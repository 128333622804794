@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.blink-animation {
  animation: blink 0.5s infinite;
  -webkit-animation: blink 0.5s infinite;
}

.quizHeader {
  /* Rectangle 5 */

  box-sizing: border-box;


  width: 450px;
  height: 92px;


  border: 3px solid #461312;
  border-radius: 30px;
  /* MATHS QUIZ */




  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 80px;
  text-align: center;

  color: #FFFFFF;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */


}

/* Glassmorphism base style */
.glassmorphism {



  background: radial-gradient(50% 50% at 50% 50%, #252E37 0%, #202A2A 100%);
  box-shadow: inset 0px 23px 17.1px 12px #6C0704;
  border-radius: 60px 60px 0px 0px;
}

/* Timer and button styles */
.timer {

  background: linear-gradient(315.7deg, #C89C18 15.4%, #E39425 50.67%, #EFAB39 82.13%);
  box-shadow: inset -7px -5px 21.5px -1px rgba(40, 40, 40, 0.75);
  border-radius: 22px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;

  line-height: 44px;

  color: #FFFFFF;
}

.board {
  /* Rectangle 8 */


  background: radial-gradient(50% 50% at 50.35% 50%, #3C4A57 0%, #252731 100%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;

}

.button-3d {
  background-color: #FFC107;
  /* Button background color */
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  /* 3D shadow */
}

.button-3d:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.3);
  /* Hover effect */
}

.button-3d:active {
  transform: translateY(1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  /* Active effect */
}

/* Input field styling for glass effect */
.input-glass {
  /* Rectangle 4 */


  width: 153px;
  height: 87px;


  background: #FFFFFF;
  box-shadow: inset 0px 0px 4.3px 4px #718EFF;
  border-radius: 20px;

  span {




    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;

    color: #000000;


  }

}

/* Question box style */
.question-box {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: linear-gradient(315.7deg, #DBAC1F 15.4%, #E08300 50.67%, #F4D197 82.13%);
  box-shadow: inset -7px -5px 21.5px -1px rgba(40, 40, 40, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;




  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 77px;

  color: #FFFFFF;


}

.signBox {
  /* Rectangle 13 */


  width: 66px;
  height: 63px;


  background: #FFFFFF;
  box-shadow: inset 0px 0px 4.3px 4px #FFBD71;
  border-radius: 20px;


  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;


  color: #000000;





}

.checkButton {

  width: 225px;
  height: 63px;






  background: linear-gradient(315.7deg, #C89C18 15.4%, #E39425 50.67%, #EFAB39 82.13%);
  box-shadow: inset -7px -5px 21.5px -1px rgba(40, 40, 40, 0.75);
  border-radius: 22px;




  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;

  color: #FFFFFF;





}