@keyframes shake {
  0%, 100% { 
    transform: translateX(0); 
    -webkit-transform: translateX(0); 
    -moz-transform: translateX(0); 
    -ms-transform: translateX(0); 
    -o-transform: translateX(0); 
  
  }
  10%, 30%, 50%, 70% { 
    transform: translateY(-10px); 
    -webkit-transform: translateY(-10px); 
    -moz-transform: translateY(-10px); 
    -ms-transform: translateY(-10px); 
    -o-transform: translateY(-10px); 
  }
  20%, 40%, 60%, 80% { 
    transform: translateX(0px); 
    -webkit-transform: translateX(0px); 
    -moz-transform: translateX(0px); 
    -ms-transform: translateX(0px); 
    -o-transform: translateX(0px); 
  }
}

.shiny_golden_text {
  color: goldenrod;
  animation: shake 2s ease-in-out infinite;
  -webkit-animation: shake 2s ease-in-out infinite;
  text-shadow: 0px 0px 10px rgba(255, 215, 0, 0.8);
}

@keyframes rotate3d {
  0% { 
    transform: rotateY(0deg); 
    -webkit-transform: rotateY(0deg); 
    -moz-transform: rotateY(0deg); 
    -ms-transform: rotateY(0deg); 
    -o-transform: rotateY(0deg); 
  }
  100% { 
    transform: rotateY(360deg); 
    -webkit-transform: rotateY(360deg); 
    -moz-transform: rotateY(360deg); 
    -ms-transform: rotateY(360deg); 
    -o-transform: rotateY(360deg); 
  }
}

.rotating_image {
  transition: transform 1s;
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  -ms-transition: transform 1s;
  -o-transition: transform 1s;
  animation: rotate3d 4s linear infinite;
  -webkit-animation: rotate3d 4s linear infinite;
}
