

.slide {
  transform: translateY(50px) scale(0.8);
  transition: transform 300ms;
  opacity: 0.5;
  -webkit-transform: translateY(50px) scale(0.8);
  -moz-transform: translateY(50px) scale(0.8);
  -ms-transform: translateY(50px) scale(0.8);
  -o-transform: translateY(50px) scale(0.8);
  pointer-events: none;
  -webkit-transition: transform 300ms;
  -moz-transition: transform 300ms;
  -ms-transition: transform 300ms;
  -o-transition: transform 300ms;
}

.activeSlide {
  pointer-events:all;
  transform: translateY(50px) scale(1.2);
  opacity: 1;
  -webkit-transform: translateY(50px) scale(1.2);
  -moz-transform: translateY(50px) scale(1.2);
  -ms-transform: translateY(50px) scale(1.2);
  -o-transform: translateY(50px) scale(1.2);
}
.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
  -webkit-transition: color 300ms;
  -moz-transition: color 300ms;
  -ms-transition: color 300ms;
  -o-transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 20%;
  top: 50%;
}

.prev {
  left: 20%;
  top: 50%;
}
@media screen and (max-width: 1024px) {
  .activeSlide {
    pointer-events:all;
    transform: translateY(50px) scale(1);
    opacity: 1;
    -webkit-transform: translateY(50px) scale(1);
    -moz-transform: translateY(50px) scale(1);
    -ms-transform: translateY(50px) scale(1);
    -o-transform: translateY(50px) scale(1);
}
  
}
