.yBig {
    font-size: 2rem; /* Adjust the size to whatever you desire */
  }
  .bgClip {
    
    background-clip: text;
-webkit-background-clip: text;
color: #6868a9;
  }
  textarea::placeholder {
    color: #5358a4;
    
  }

  .bg-ebebed {
    background-color: #ebebed;
    overflow-y: scroll; /* Ensuring only vertical scroll */
    font-weight: bold;
    color: #5357a3;
 
}

/* Add the custom scrollbar styles as mentioned in the previous answer. */
/* Custom scrollbar styling */

/* For WebKit browsers */
.bg-ebebed::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.bg-ebebed::-webkit-scrollbar-track {
  background: transparent; /* color of the track */
}

.bg-ebebed::-webkit-scrollbar-thumb {
  background-color: #5357a3; /* color of the thumb */
  border-radius: 5px; /* rounded corners for the thumb */
  border: 2px solid #ebebed; /* create a border around the thumb */
}

/* For Firefox */
.bg-ebebed {
  scrollbar-width: thin;
  scrollbar-color: #5357a3 transparent;
}
