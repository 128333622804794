.logo_header {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;

  text-shadow: 0px 4px 8.2px rgba(0, 0, 0, 0.25);
}

.logo_description{
  
}

.generate_button{
position: absolute;
width: 181px;
height: 51px;
left: 149px;
top: calc(50% - 51px/2 + 297.5px);


border-radius: 7px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;

mix-blend-mode: normal;
}